
/*/deep/.pages-center{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin-top: 24px;*/
/*}*/
/deep/.el-table--border::after, .el-table--group::after, .el-table::before{
    /*display: none;*/
}
.plan-name {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: #1E63F1;
    .edit-plan-iocn {
        display: none;
        font-size: 14px;
        margin-left: 10px;
    }
    &:hover {
        color: #1E63F1;
        .edit-plan-iocn {
            display: inline-block;
            color: #333;
        }
    }
}
.name-input {
    display: block;
    position: absolute;
    top: 40px;
    height: 18px;
    width: 190px;
}
/deep/.el-table .el-table__body tr td{
            padding: 19px 0;
}
/deep/.el-breadcrumb .el-breadcrumb__item .el-breadcrumb__inner{
    color: #9A9A9A;
    font-size: 14px;
}
/deep/.el-button--default:hover{
    border-color: #FD4446;
    color: #FD4446;
    background: #ffffff;
}
/deep/.el-checkbox .is-checked .el-checkbox__inner{
    background-color: #FD4446;
    border-color: #FD4446;
}
/deep/.is-indeterminate .el-checkbox__inner{
    background-color: #FD4446;
    border-color: #FD4446;
}
/deep/.is-checked .el-radio__inner{
    border-color: #FD4446!important;
    background: #FD4446!important;
}
/deep/.el-radio__inner{
    width: 20px;
    height: 20px;
}
/deep/.is-checked .el-radio__label{
    color: #333333!important;
    font-size: 18px;
}
/deep/.el-radio .el-radio__label{
    color: #1E63F1;
    font-size: 18px;
}
.isShowActive{
    background: #FFECC8!important;
    border-color: #ffffff!important;
}
/deep/.el-tabs__nav-wrap::after{
    /*display: none;*/
    height: 1px;
}
/deep/.el-tabs__nav .is-active{
    color: #FD4446;
    font-weight: 400;
    font-size: 20px;
}
/deep/.el-tabs__active-bar{
    background-color: #FD4446;
}
/deep/.el-tabs__item{
    line-height: 30px;
}
.editIcon{
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
}

.BoothAdvertising{
    min-height: calc(100vh - 120px);
    /*height: 100%;*/
    background: #F7F9FB;
    .BoothAdvertisingHeader{
        span{
            font-size: 14px;
        }
    }
    .promotion-txt{
        display: flex;
        justify-content: space-between;
        color: #333333;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        margin: 20px 0;
        span:nth-child(2){
            font-size: 18px;
            color: #FD4446;
            font-family: Avenir, Helvetica, Arial, sans-serif;
        }
        b{
            font-size: 12px;
            margin: 0 2px;
            font-weight: 500;
        }
    }
    .promotion-plan{
        height: calc(100vh - 270px);
        padding: 20px;
        background: #ffffff;
        .promotion-mid{
            .tableBtn{
                padding-top: 10px;
                .btn1{
                    background: #FD4446;
                    color: #ffffff;
                    border-color:#FD4446;
                }
                .btn2{
                    margin-left: 10px;
                }
            }
            .promotion-table{
            }
        }
    }
}
.CustomBudgetBox{
    display: flex;
    flex-direction: column;
    padding: 40px 60px;
    span{
        color: #989898;
        font-size: 18px;
        line-height: 1;
        margin-top: 20px;
    }
    .title{
        color: #333333;
        font-size: 24px;
        padding-bottom: 20px;
    }
    span:nth-child(4){
        line-height: 27px;
    }
    .box-bottom{
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
    }

}
.hoverWrapper {
    padding: 10px 10px 10px 20px;
    z-index: 3302;
    position: fixed;
    top: 10px;
    left: 20px;
    width: 210px;
    /*height: 200px;*/
    background-color: #FFF;
    border: 1px solid #EEE;
    font-size: 18px;
    p{
        line-height: 27px;
    }
}
.clickWrapper{
    z-index: 3302;
    position: fixed;
    top: 10px;
    left: 20px;
    width: 246px;
    padding-top: 20px;
    background-color: #FFF;
    border: 1px solid #EEE;
    padding-left: 20px;
    font-size: 18px;
    padding-bottom: 30px;
    /deep/.el-radio{
        margin-top: 20px;
    }
    /deep/.el-radio-group{
        display: flex;
        flex-direction: column;
    }
    /deep/.el-radio__label{
        color: #333333!important;
        font-size: 18px;
    }
    .customInput{
        margin-top: 10px;
        display: flex;
        margin-left: 30px;
        padding-right: 34px;
        align-items: center;
        span{
            font-size: 18px;
        }
    }
    .clickWrapperBtn{
        margin-top: 20px;
        padding-left: 44px;
    }
}
